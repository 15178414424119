<template>
  <div class="chart-contanier">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="当前在院患者血糖">
        <div v-if="currentTab == 1">
          <searchPlate @search="getData"></searchPlate>
          <div class="blood-sugar">
            <lineChart id="bloodSugar" :dataVolume="dataVolume" :echartData="bloodSugarInfo"></lineChart>
          </div>
          <div class="tir-cv">
            <lineUnitChart id="TirAndCv" :cvStatisticsNum="cvStatisticsNum" :tirStatisticsNum="tirStatisticsNum"
              :echartData="TirAndCvInfo"></lineUnitChart>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="全部患者血糖" force-render>
        <div v-if="currentTab == 2">
          <searchPlate @search="getData"></searchPlate>
          <div class="blood-sugar">
            <lineChart id="bloodSugar" :dataVolume="dataVolume" :echartData="bloodSugarInfo"></lineChart>
          </div>
          <div class="tir-cv">
            <lineUnitChart id="TirAndCv" :cvStatisticsNum="cvStatisticsNum" :tirStatisticsNum="tirStatisticsNum"
              :echartData="TirAndCvInfo"></lineUnitChart>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane @click="getPatientIndividual" key="3" tab="患者个人血糖">
        <div v-if="currentTab == 3">
          <div class="filter-items">
            <a-row>
              <a-col v-if="!isHospital">
                <a-select placeholder="请选择医院" style="width: 180px" v-model="individualParams.hospitalId"
                  :allow-clear="false" @select="selectedHospital">
                  <a-select-option v-for="(item, index) in hospitalList" :key="index" :value="item.hospitalId">{{
      item.name }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select show-search option-filter-prop="children" placeholder="请选择病案号" style="width: 180px"
                  v-model="individualParams.patientId" :allow-clear="false" @search="searchPatient"
                  :disabled="!individualParams.hospitalId">
                  <a-select-option v-for="(item, index) in patientList" :key="index"
                    :value="item.patientId + '_' + index">{{ item.recordNumber }}</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-button @click="getPatientIndividual" type="primary">查询</a-button>
              </a-col>
            </a-row>
          </div>
          <div class="blood-sugar">
            <lineChart id="bloodSugarPatient" :echartData="bloodSugarInfo"></lineChart>
          </div>
          <div class="tir-cv">
            <lineUnitChart id="TirAndCvPatient" :echartData="TirAndCvInfo"></lineUnitChart>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>

import api from "@/api/index.js";
import storage from "../../storage/storage.js";

import searchPlate from "./search.vue";
import lineChart from "../charts/line.vue";
import lineUnitChart from "../charts/lineUnit.vue";
export default {
  components: { lineChart, lineUnitChart, searchPlate },
  data() {
    return {
      currentTab: 1,
      // 个人血糖检测 
      bloodSugarInfo: {
        title: '血糖监测',
        yAxisUnit: '',
        xAxisData: [],
        series: [{
          name: "血糖",
          color: '#6093FF',
          yAxisData: [],
        }
        ],
        noData: true
      },
      // TIR/CV监测
      TirAndCvInfo: {
        title: 'TIR/CV监测',
        yAxisUnit: '',
        xAxisData: [],
        series: [{
          name: "TIR",
          color: '#06C270',
          yAxisData: [],
        },
        {
          name: "CV",
          color: '#FF8800',
          yAxisData: [],
        }
        ],
        noData: true
      },
      hospitalList: storage.getItem("hospitalList"),
      patientList: [],
      patientKeyword: '',//患者列表关键字查询
      // 个人查询条件
      isHospital: false,
      individualParams: {
        hospitalId: undefined,
        patientId: undefined,
      },
      dataVolume: [],//数据量
      cvStatisticsNum: [],//数据量
      tirStatisticsNum: [],//数据量
    };
  },
  mounted() {
    let users = storage.getItem("users");
    if (users && users.hospitalId) {
      this.isHospital = true;
      this.individualParams.hospitalId = users.hospitalId;
      this.getPatientList(1);
    } else {
      this.isHospital = false;
    }
  },

  methods: {
    getData(params) {
      this.clearData();

      this.getPatientBloodSugar(params);
      this.getTirAndCvRecordMedian(params);
    },
    // 获取TIR/CV监测
    getTirAndCvRecordMedian(params) {
      let { sex, minAge, maxAge, diagnosisId, hospitalId, minIcuDays, maxIcuDays } = params.data;
      let paramsData = {
        sex,
        minAge,
        maxAge,
        diagnosisId,
        startTime: params.selectedRange[0],
        endTime: params.selectedRange[1],
        hospitalId,
        minIcuDays: minIcuDays ? Number(minIcuDays) : null,
        maxIcuDays: maxIcuDays ? Number(maxIcuDays) : null,
      }
      if (this.currentTab == 1) {
        paramsData.inIcu = '1' // 当前在院患者血糖
      }
      api
        .getPatientsTirAndCvRecordMedian(paramsData)
        .then(({ data }) => {
          const result = Object.entries(data).map(([name, value]) => ({
            name,
            tir: typeof value.tir == "number" ? (value.tir * 100).toFixed(2) : null,
            cv: typeof value.cv == "number" ? value.cv.toFixed(2) : null,
            cvStatisticsNum: typeof value.cvStatisticsNum == "number" ? value.cvStatisticsNum : null,
            tirStatisticsNum: typeof value.tirStatisticsNum == "number" ? value.tirStatisticsNum : null
          }));

          if (result.length == 0) {
            this.TirAndCvInfo.noData = true;
          }

          this.TirAndCvInfo.xAxisData = result.map(v => v.name);
          this.TirAndCvInfo.series[0].yAxisData = result.map(v => v.tir);
          this.TirAndCvInfo.series[1].yAxisData = result.map(v => v.cv);
          this.cvStatisticsNum = result.map(v => v.cvStatisticsNum);
          this.tirStatisticsNum = result.map(v => v.tirStatisticsNum);
        });
    },
    // 获取患者血糖
    getPatientBloodSugar(params) {
      let { sex, minAge, maxAge, diagnosisId, hospitalId, minIcuDays, maxIcuDays } = params.data;
      let paramsData = {
        sex,
        minAge,
        maxAge,
        diagnosisId,
        startTime: params.selectedRange[0],
        endTime: params.selectedRange[1],
        hospitalId,
        minIcuDays: minIcuDays ? Number(minIcuDays) : null,
        maxIcuDays: maxIcuDays ? Number(maxIcuDays) : null,
      }
      if (this.currentTab == 1) {
        paramsData.inIcu = '1' // 当前在院患者血糖
      }
      api
        .getPatientsGlsMedian(paramsData)
        .then(({ data }) => {
          const result = Object.entries(data).map(([name, value]) => ({
            name,
            gls: value?.gls?.median || null,
            num: value?.gls?.num || null
          }));

          if (result.length == 0) {
            this.bloodSugarInfo.noData = true;
          }

          this.bloodSugarInfo.xAxisData = result.map(v => v.name);
          this.bloodSugarInfo.series[0].yAxisData = result.map(v => v.gls);
          this.dataVolume = result.map(v => v.num);
        });
    },
    // 获取患者个人信息
    getPatientIndividual() {
      this.clearData();

      this.getSickinquire(); //血糖
      this.getTirAndCv(); //TIR/CV 
    },
    // 获取患者列表
    getPatientList(page) {
      // 重新查询数据时，先清空
      if (page == 1) {
        this.patientList = [];
      }

      let pagination = {
        current: page, //当前页
        pageSize: 100, //每页几条数据
      }
      let obj = {
        name: this.patientKeyword,
        pagination: pagination,
        hospitalId: this.individualParams.hospitalId
      };
      api
        .patient(obj)
        .then((res) => {
          this.patientList = this.patientList.concat(res.rows);
          if (res.total > (page - 1) * 100 + 100) {
            this.getPatientList(page + 1)
          }
        })
        .catch(() => { });
    },
    // 选中医院
    selectedHospital(value) {
      this.individualParams.patientId = undefined,
        this.getPatientList(1);
    },
    searchPatient(value) {
      this.patientKeyword = value;
      this.getPatientList(1);
    },
    // 获取患者详情
    getSickinquire() {
      if (!this.individualParams.patientId) {
        return;
      }
      let patientId = this.individualParams.patientId.split("_")[0];
      api
        .listGlsRecord({ patientId })
        .then(({ data }) => {
          let gls_speed = {
            name: "RI",
            color: '#FFE600',
            yAxisData: [],
          }
          data.forEach((data) => {
            if (data.length == 0) {
              this.bloodSugarInfo.noData = true;
            } else {
              this.bloodSugarInfo.noData = false;
            }

            this.bloodSugarInfo.xAxisData.push(data.createTime);
            this.bloodSugarInfo.series[0].yAxisData.push(data.gls);
            gls_speed.yAxisData.push(data.adviseInsulinSpeed);
          });
          this.bloodSugarInfo.series.push(gls_speed);
        });
    },
    getTirAndCv() {
      if (!this.individualParams.patientId) {
        return;
      }
      let patientId = this.individualParams.patientId.split("_")[0]
      api
        .getPatientTirAndCvRecordMedian({ patientId })
        .then((res) => {
          res.data.forEach((data) => {
            if (data.length == 0) {
              this.TirAndCvInfo.noData = true;
            } else {
              this.TirAndCvInfo.noData = false;
            }
            this.TirAndCvInfo.xAxisData.push('Day' + data.day);
            this.TirAndCvInfo.series[0].yAxisData.push(typeof data.tir == "number" ? (data.tir * 100).toFixed(2) : null);
            this.TirAndCvInfo.series[1].yAxisData.push(typeof data.cv == "number" ? data.cv.toFixed(2) : null);
          });
        });
    },
    callback(key) {
      this.clearData();
      this.currentTab = key;

      if (key == 3) {
        this.getPatientIndividual();
      }
    },
    clearData() {
      this.bloodSugarInfo.xAxisData = [];
      this.bloodSugarInfo.series = [{
        name: "血糖",
        color: '#6093FF',
        yAxisData: [],
      }]

      this.TirAndCvInfo.xAxisData = [];
      this.TirAndCvInfo.series[0].yAxisData = [];
      if (this.TirAndCvInfo.series[1]) {
        this.TirAndCvInfo.series[1].yAxisData = [];
      }
    },
  }
};
</script>
<style scoped lang="less">
.blood-sugar {
  height: calc((100vh - 65px) / 2);
  margin-bottom: 18px;
  background: #fff;
  border-radius: 30px;
  padding: 25px 30px 30px;
}

.tir-cv {
  height: calc((100vh - 65px) / 2);
  background: #fff;
  border-radius: 30px;
  padding: 25px 30px 30px;
}

.filter-items {
  background: #fff;
  border-radius: 0 0 30px 30px;
  padding: 30px 30px 15px;
  margin-bottom: 15px;

  .ant-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .ant-col {
      margin-right: 20px;
      margin-bottom: 15px;

      .ant-input {
        width: 68px;
      }

      .ant-btn-primary {
        background: #2468f2;
      }
    }
  }
}

/deep/.ant-tabs-bar {
  background: #fff;
  border-radius: 30px 30px 0 0;
  padding: 28px 30px 0;
  margin-bottom: 0;
}

/deep/.ant-tabs-nav .ant-tabs-tab {
  padding: 0 0 18px 0;
  margin-right: 9vw;
}

.chart-contanier {
  height: 100%;
  overflow: auto;
}
</style>